import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  documents: [],
  noteDocuments: [],
  document: {},
  count: 0,
  isModalOpen: false,
  selectedDocumentId: null,
  selectedRange: null,
  updated: false
};

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getDocuments(state, action) {
      const { documents, count } = action.payload;

      state.document = {};
      state.documents = documents;
      state.count = count;
    },
    getNoteDocuments(state, action) {
      const { noteDocuments } = action.payload;
      state.noteDocuments = noteDocuments;
    },
    getDocument(state, action) {
      const document = action.payload;

      state.document = document;
    },
    createDocument(state, action) {
      const document = action.payload;
      state.document = document;
      state.updated = !state.updated
    },
    updateDocument(state, action) {
      const document = action.payload;

      state.documents = _.map(state.documents, (_document) => {
        if (_document._id === document._id) {
          return document;
        }

        return _document;
      });
      state.updated = !state.updated
      state.document = document;
    },
    deleteDocument(state, action) {
      const { documentId } = action.payload;

      state.documents = _.reject(state.documents, { _id: documentId });
    }
  }
});

export const reducer = slice.reducer;

export const getDocuments = (userId) => async (dispatch) => {
  console.log("hellooo2", userId)
  const response = await axios.get(
    `/documents?userId=${userId}`
  );

  dispatch(slice.actions.getDocuments(response.data));

  return response.data.documents
};

export const getNoteDocuments = () => async (dispatch) => {
  const response = await axios.get(
    `/documents/noteDocuments`
  );
  console.log("hellooo", response.data)
  dispatch(slice.actions.getNoteDocuments(response.data));

  return response.data.noteDocuments
};


export const getDocument = (id) => async (dispatch) => {
  const response = await axios.get(`/documents/${id}`);

  dispatch(slice.actions.getDocument(response.data));
};

export const createDocument = (data) => async (dispatch) => {
  const response = await axios.post(`/documents`, data);

  await dispatch(slice.actions.createDocument(response.data));

  return response.data
};

export const updateDocument = (documentId, update) => async (dispatch) => {
  const response = await axios.put(`/documents/${documentId}`, update);

  await dispatch(slice.actions.updateDocument(response.data));

  return response.data
};

export const deleteDocument = (documentId) => async (dispatch) => {
  await axios.delete(`/documents/${documentId}`, {});

  dispatch(slice.actions.deleteDocument({ documentId }));
};

export default slice;
