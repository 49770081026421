import { combineReducers } from '@reduxjs/toolkit';
import { reducer as dashboardReducer } from 'src/slices/dashboard';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as lendersReducer } from 'src/slices/lenders';
import { reducer as borrowersReducer } from 'src/slices/borrowers';
import { reducer as adminUsersReducer } from 'src/slices/adminUsers';
import { reducer as promNotesReducer } from 'src/slices/promNotes';
import { reducer as termsReducer } from 'src/slices/terms';
import { reducer as transactionReducer } from 'src/slices/transactions';
import { reducer as documentReducer } from 'src/slices/documents';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  lenders: lendersReducer,
  borrowers: borrowersReducer,
  adminUsers: adminUsersReducer,
  promNotes: promNotesReducer,
  terms: termsReducer,
  notifications: notificationReducer,
  transactions: transactionReducer,
  documents: documentReducer,
});

export default rootReducer;
