/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  Info as InfoIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  FileText as NoteIcon,
} from "react-feather";
import Logo from "src/components/Logo";
import useAuth from "src/hooks/useAuth";
import { useDispatch, useSelector } from "src/store";
import NavItem from "./NavItem";
import { isBoolean } from "lodash";
const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    user,
    canAccess,
    actions,
    sections,
    isAdmin,
    readOnly,
    isLender,
    isBorrower,
  } = useAuth();
  const dispatch = useDispatch();
  var navSections = [];
  if (isAdmin()) {
    navSections = [
      {
        subheader: "Reports",
        items: [
          {
            title: "Dashboard",
            icon: PieChartIcon,
            section: "dashboard",
            href: "/app/dashboard",
          },
        ],
      },
      {
        subheader: "Management",
        items: [
          {
            title: "Users",
            icon: UsersIcon,
            href: "#",
            items: [
              {
                title: "Borrowers",
                section: "borrowers",
                href: "/app/borrowers",
              },
              {
                title: "Lenders",
                section: "lenders",
                href: "/app/lenders",
              },
            ],
          },
          {
            title: "Loan Notes",
            icon: NoteIcon,
            href: "/app/promNotes",
          },
          {
            title: "Office",
            icon: BriefcaseIcon,
            href: "#",
            items: [
              {
                title: "Transactions",
                section: "transactions",
                href: "/app/transactions",
              },
              {
                title: "Treasury Account",
                section: "my-p2p-account",
                href: "/app/my-p2p-account",
              },
              {
                title: "Terms",
                section: "terms",
                href: "/app/terms",
              },
              !readOnly() ? 
              {
                title: "Admins",
                section: "adminUsers",
                href: "/app/adminUsers",
              } 
              : null, 
            ].filter(item => item !== null),
          },
        ],
      },
    ];
  }
  if (isLender()) {
    navSections = [
      {
        subheader: "Menu",
        items: [
          {
            title: "Dashboard",
            icon: PieChartIcon,
            section: "dashboard",
            href: "/app/dashboard",
          },
        ],
      },

      {
        items: [
          {
            title: "About",
            icon: InfoIcon,
            href: "/app/about",
          },
        ],
      },
      {
        items: [
          {
            title: "Loan Notes",
            icon: NoteIcon,
            href: "/app/promNotes",
          },
        ],
      },
      {
        items: [
          {
            title: "Your P2P Balances",
            icon: DollarSignIcon,
            href: "/app/my-p2p-account",
          },
        ],
      },
    ];
  }

  if (isBorrower()) {
    navSections = [
      {
        subheader: "Menu",
        items: [
          {
            title: "Dashboard",
            icon: PieChartIcon,
            section: "dashboard",
            href: "/app/dashboard",
          },
        ],
      },
      {
        items: [
          {
            title: "Loan Notes",
            icon: NoteIcon,
            href: "/app/promNotes",
          },
          {
            title: "Your P2P Balances",
            icon: DollarSignIcon,
            href: "/app/my-p2p-account",
          },
        ],
      },
    ];
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user]);

  const renderNavItems = ({ items, pathname, depth = 0 }) => {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
    );
  };

  const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false,
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
          })}
        </NavItem>
      );
    } else {
      // if (item.section && canAccess(item.section, actions.VIEW))
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Divider />
        <Box p={2}>
          {navSections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
