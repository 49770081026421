import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  borrowers: [],
  borrowerOptions: [],
  promNotes: [],
  borrower: {},
  count: 0,
  isModalOpen: false,
  selectedBorrowerId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'borrower',
  initialState,
  reducers: {
    getBorrowers(state, action) {
      const { borrowers, count } = action.payload;
      state.borrower = {};
      state.borrowers = borrowers;
      state.count = count;
    },
    getBorrowerOptions(state, action) {
      state.borrowerOptions = action.payload;
    },
    getBorrower(state, action) {
      const borrower = action.payload;

      state.borrower = borrower;
    },
    getPromNotes(state, action) {
      const promNotes = action.payload;
      state.promNotes = promNotes;
    },
    createBorrower(state, action) {
      const borrower = action.payload;

      state.borrowers = [...state.borrowers, borrower];
      state.borrower = borrower;
    },
    updateBorrower(state, action) {
      const borrower = action.payload;

      state.borrowers = _.map(state.borrowers, (_borrower) => {
        if (_borrower._id === borrower._id) {
          return borrower;
        }

        return _borrower;
      });
      state.borrower = borrower;
    },
    deleteBorrower(state, action) {
      const { borrowerId } = action.payload;

      state.borrowers = _.reject(state.borrowers, { _id: borrowerId });
    }
  }
});

export const reducer = slice.reducer;

export const getBorrowers = (page, limit, data) => async (dispatch) => {
  const response = await axios.get(
    `/borrowers?page=${page}&perPage=${limit}`
    // `/borrowers`
  );
  dispatch(slice.actions.getBorrowers(response.data));
};

export const getBorrower = (id) => async (dispatch) => {
  const response = await axios.get(`/borrowers/${id}`);

  dispatch(slice.actions.getBorrower(response.data));
};

export const createBorrower = (data) => async (dispatch) => {
  const response = await axios.post('/borrowers', data);

  await dispatch(slice.actions.createBorrower(response.data));

  return response.data
};

export const updateBorrower = (borrowerId, update) => async (dispatch) => {
  const response = await axios.put('/borrowers/' + borrowerId, update);

  await dispatch(slice.actions.updateBorrower(response.data));

  return response.data
};

export const getPromNotes = (id) => async (dispatch) => {
  const response = await axios.get(`/borrowers/${id}/promNotes`);

  dispatch(slice.actions.getPromNotes(response.data));
};


export const deleteBorrower = (borrowerId) => async (dispatch) => {
  await axios.delete('/borrowers/' + borrowerId, {});

  dispatch(slice.actions.deleteBorrower({ borrowerId }));
};

export const getBorrowerOptions = () => async (dispatch) => {
  const response = await axios.get(`/borrowers/as-options`);

  dispatch(slice.actions.getBorrowerOptions(response.data));
};


export default slice;
