import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AdminOrBorrowerGuard = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, saveToken, isAdmin, isBorrower } = useAuth();
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    console.log("helloooo9999")
    var token = localStorage.getItem("accessToken")
    if (!token) {
      autoLogin();
    }
    else setLoaded(true)
  }, []);

  const autoLogin = async () => {
    const urlParams = new URLSearchParams(location.search);
    const secret_token = urlParams.get("token");
    console.log("helloooo9999", secret_token)
    if (secret_token) {
      await saveToken(secret_token)
      setLoaded(true)
    }
    else setLoaded(true)
  }

  if (!isAuthenticated && loaded) {
    return <Redirect to="/login" />;
  } else {
    if (!isAdmin() && !isBorrower() && loaded)
      return <Redirect to="/app/dashboard" />;
  }

  return (
    loaded ?
      <>
        { children}
      </>
      : ''
  );
};

AdminOrBorrowerGuard.propTypes = {
  children: PropTypes.node
};

export default AdminOrBorrowerGuard;