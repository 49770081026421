import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  lenders: [],
  bids: [],
  lenderOptions: [],
  lender: {},
  count: 0,
  isModalOpen: false,
  selectedLenderId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'lender',
  initialState,
  reducers: {
    getLenders(state, action) {
      const { lenders, count } = action.payload;
      state.lender = {};
      state.lenders = lenders;
      state.count = count;
    },
    getLenderOptions(state, action) {
      state.lenderOptions = action.payload;
    },
    getLender(state, action) {
      const lender = action.payload;

      state.lender = lender;
    },
    getBids(state, action) {
      const bids = action.payload;
      state.bids = bids;
    },
    createLender(state, action) {
      const lender = action.payload;

      state.lenders = [...state.lenders, lender];
      state.lender = lender;
    },
    updateLender(state, action) {
      const lender = action.payload;

      state.lenders = _.map(state.lenders, (_lender) => {
        if (_lender._id === lender._id) {
          return lender;
        }

        return _lender;
      });
      state.lender = lender;
    },
    deleteLender(state, action) {
      const { lenderId } = action.payload;

      state.lenders = _.reject(state.lenders, { _id: lenderId });
    }
  }
});

export const reducer = slice.reducer;

export const getLenders = (page, limit, data) => async (dispatch) => {
  const response = await axios.get(
    `/lenders?page=${page}&perPage=${limit}`
    // `/lenders`
  );
  dispatch(slice.actions.getLenders(response.data));
};

export const getLender = (id) => async (dispatch) => {
  const response = await axios.get(`/lenders/${id}`);

  dispatch(slice.actions.getLender(response.data));
};

export const getBids = (id) => async (dispatch) => {
  console.log("helloooo33")
  const response = await axios.get(`/lenders/${id}/bids`);

  dispatch(slice.actions.getBids(response.data));
};


export const createLender = (data) => async (dispatch) => {
  const response = await axios.post('/lenders', data);

  await dispatch(slice.actions.createLender(response.data));

  return response.data
};

export const updateLender = (lenderId, update) => async (dispatch) => {
  const response = await axios.put('/lenders/' + lenderId, update);

  await dispatch(slice.actions.updateLender(response.data));

  return response.data
};

export const deleteLender = (lenderId) => async (dispatch) => {
  await axios.delete('/lenders/' + lenderId, {});

  dispatch(slice.actions.deleteLender({ lenderId }));
};

export const getLenderOptions = () => async (dispatch) => {
  const response = await axios.get(`/lenders/as-options`);

  dispatch(slice.actions.getLenderOptions(response.data));
};

export const impersonateLender = (lenderId) => async (dispatch) => {
  const response = await axios.get(`/lenders/${lenderId}/impersonate`);

  return response.data
};

export default slice;
