import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  adminUsers: [],
  adminUserOptions: [],
  adminUser: {},
  count: 0,
  isModalOpen: false,
  selectedAdminUserId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    getAdminUsers(state, action) {
      const { adminUsers, count } = action.payload;
      state.adminUser = {};
      state.adminUsers = adminUsers;
      state.count = count;
    },
    getAdminUserOptions(state, action) {
      state.adminUserOptions = action.payload;
    },
    getAdminUser(state, action) {
      const adminUser = action.payload;

      state.adminUser = adminUser;
    },
    createAdminUser(state, action) {
      const adminUser = action.payload;

      state.adminUsers = [...state.adminUsers, adminUser];
      state.adminUser = adminUser;
    },
    updateAdminUser(state, action) {
      const adminUser = action.payload;

      state.adminUsers = _.map(state.adminUsers, (_adminUser) => {
        if (_adminUser._id === adminUser._id) {
          return adminUser;
        }

        return _adminUser;
      });
      state.adminUser = adminUser;
    },
    deleteAdminUser(state, action) {
      const { adminUserId } = action.payload;

      state.adminUsers = _.reject(state.adminUsers, { _id: adminUserId });
    }
  }
});

export const reducer = slice.reducer;

export const getAdminUsers = (page, limit, data) => async (dispatch) => {
  const response = await axios.get(
    // `/adminUsers?page=${page}&perPage=${limit}`
    `/adminUsers`
  );
  dispatch(slice.actions.getAdminUsers(response.data));
};

export const getAdminUser = (id) => async (dispatch) => {
  const response = await axios.get(`/adminUsers/${id}`);

  dispatch(slice.actions.getAdminUser(response.data));
};

export const createAdminUser = (data) => async (dispatch) => {
  const response = await axios.post('/adminUsers', data);

  await dispatch(slice.actions.createAdminUser(response.data));

  return response.data
};

export const updateAdminUser = (adminUserId, update) => async (dispatch) => {
  const response = await axios.put('/adminUsers/' + adminUserId, update);

  await dispatch(slice.actions.updateAdminUser(response.data));

  return response.data
};

export const deleteAdminUser = (adminUserId) => async (dispatch) => {
  await axios.delete('/adminUsers/' + adminUserId, {});

  dispatch(slice.actions.deleteAdminUser({ adminUserId }));
};

export const getAdminUserOptions = () => async (dispatch) => {
  const response = await axios.get(`/adminUsers/as-options`);

  dispatch(slice.actions.getAdminUserOptions(response.data));
};

export default slice;
