import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  transactions: [],
  history: [],
  historyCount: 0,
  userOptions: [],
  users: [],
  promNotes: [],
  transaction: {},
  count: 0,
  isModalOpen: false,
  selectedTransactionId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    getTransactions(state, action) {
      const { transactions, count } = action.payload;
      state.transaction = {};
      state.transactions = transactions;
      state.count = count;
    },
    getUserOptions(state, action) {
      state.userOptions = action.payload;
    },
    getUsers(state, action) {
      state.users = action.payload;
    },
    getHistory(state, action) {
      const { history, count } = action.payload;
      state.history = history;
      state.historyCount = count
    },
    getTransaction(state, action) {
      const transaction = action.payload;

      state.transaction = transaction;
    },
    getPromNotes(state, action) {
      const promNotes = action.payload;
      state.promNotes = promNotes;
    },
    createTransaction(state, action) {
      const transaction = action.payload;

      state.transactions = [...state.transactions, transaction];
      state.transaction = transaction;
    },
    updateTransaction(state, action) {
      const transaction = action.payload;

      state.transactions = _.map(state.transactions, (_transaction) => {
        if (_transaction._id === transaction._id) {
          return transaction;
        }

        return _transaction;
      });
      state.transaction = transaction;
    },
    deleteTransaction(state, action) {
      const { transactionId } = action.payload;

      state.transactions = _.reject(state.transactions, { _id: transactionId });
    }
  }
});

export const reducer = slice.reducer;

export const getTransactions = (page, limit, data) => async (dispatch) => {
  console.log("hellooookoioiikoikoikoiokoikoko")
  const response = await axios.get(
    // `/transactions?page=${page}&perPage=${limit}`
    `/transactions`
  );
  dispatch(slice.actions.getTransactions(response.data));
};

export const getTransaction = (id) => async (dispatch) => {
  const response = await axios.get(`/transactions/${id}`);

  dispatch(slice.actions.getTransaction(response.data));
};

export const createTransaction = (data) => async (dispatch) => {
  const response = await axios.post('/transactions', data);

  await dispatch(slice.actions.createTransaction(response.data));

  return response.data
};

export const updateTransaction = (transactionId, update) => async (dispatch) => {
  const response = await axios.put('/transactions/' + transactionId, update);

  await dispatch(slice.actions.updateTransaction(response.data));

  return response.data
};


export const getUserOptions = () => async (dispatch) => {
  const response = await axios.get(`/users/as-options`);

  dispatch(slice.actions.getUserOptions(response.data));
};

export const getUsers = () => async (dispatch) => {
  const response = await axios.get(`/users`);

  dispatch(slice.actions.getUsers(response.data));
};

export const getHistory = (page, limit, id) => async (dispatch) => {
  const response = await axios.get(`/transactions/history?page=${page}&perPage=${limit}&id=${id}`);

  dispatch(slice.actions.getHistory(response.data));
};


export default slice;
