import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  terms: [],
  termOptions: [],
  term: {},
  count: 0,
  isModalOpen: false,
  selectedTermId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'term',
  initialState,
  reducers: {
    getTerms(state, action) {
      const { terms, count } = action.payload;
      state.term = {};
      state.terms = terms;
      state.count = count;
    },
    getTermOptions(state, action) {
      state.termOptions = action.payload;
    },
    getTerm(state, action) {
      const term = action.payload;

      state.term = term;
    },
    createTerm(state, action) {
      const term = action.payload;

      state.terms = [...state.terms, term];
      state.term = term;
    },
    updateTerm(state, action) {
      const term = action.payload;

      state.terms = _.map(state.terms, (_term) => {
        if (_term._id === term._id) {
          return term;
        }

        return _term;
      });
      state.term = term;
    },
    deleteTerm(state, action) {
      const { termId } = action.payload;

      state.terms = _.reject(state.terms, { _id: termId });
    }
  }
});

export const reducer = slice.reducer;

export const getTerms = (page, limit, data) => async (dispatch) => {
  const response = await axios.get(
    `/terms?page=${page}&perPage=${limit}`
    // `/terms`
  );
  dispatch(slice.actions.getTerms(response.data));
};

export const getTerm = (id) => async (dispatch) => {
  const response = await axios.get(`/terms/${id}`);

  dispatch(slice.actions.getTerm(response.data));
};

export const createTerm = (data) => async (dispatch) => {
  const response = await axios.post('/terms', data);

  await dispatch(slice.actions.createTerm(response.data));

  return response.data
};

export const updateTerm = (termId, update) => async (dispatch) => {
  const response = await axios.put('/terms/' + termId, update);

  await dispatch(slice.actions.updateTerm(response.data));

  return response.data
};

export const deleteTerm = (termId) => async (dispatch) => {
  await axios.delete('/terms/' + termId, {});

  dispatch(slice.actions.deleteTerm({ termId }));
};

export const getTermOptions = () => async (dispatch) => {
  const response = await axios.get(`/terms/active`);

  dispatch(slice.actions.getTermOptions(response.data));
};


export default slice;
