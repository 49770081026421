import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  dashboard: null,
  growth: null,
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboard(state, action) {
      const dashboard = action.payload;
      state.dashboard = dashboard;
    },
    getNoteGrowth(state, action) {
      const growth = action.payload;
      state.growth = growth;
    },
  }
});

export const reducer = slice.reducer;

export const getDashboard = () => async (dispatch) => {
  const response = await axios.get('/users/dashboard');
  console.log("helloooooo", response.data)
  dispatch(slice.actions.getDashboard(response.data));
};

export const getNoteGrowth = () => async (dispatch) => {
  const response = await axios.get('/promNotes/stats');
  dispatch(slice.actions.getNoteGrowth(response.data));
};

export default slice;
